import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './SearchComponent.css';
import { useNavigate } from 'react-router-dom';

const Book = () => {
  const [destination, setDestination] = useState('');
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    if (!destination) {
      alert('Please enter a location before searching.');
      return;
    }

    console.log({
      destination,
      checkInDate,
      checkOutDate,
      adults,
      children,
      rooms,
    });

    // Navigate to the Hotel page
    navigate('/hotel');
  };

  const handleIncrement = (setter, value) => setter(value + 1);
  const handleDecrement = (setter, value) => {
    if (value > 0) setter(value - 1);
  };

  return (
    <div className="container my-4">
      <div className="row g-2 align-items-center border border-warning rounded p-3">
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            placeholder="&#xf015; Location"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            style={{ fontFamily: 'FontAwesome, Times New Roman' }}
          />
        </div>
        <div className="col-md-3">
          <div className="input-group">
            <span className="input-group-text">
              <i className="fas fa-calendar-alt"></i>
            </span>
            <DatePicker
              selected={checkInDate}
              onChange={(date) => setCheckInDate(date)}
              className="form-control"
              placeholderText="Check-in date"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group">
            <span className="input-group-text">
              <i className="fas fa-calendar-alt"></i>
            </span>
            <DatePicker
              selected={checkOutDate}
              onChange={(date) => setCheckOutDate(date)}
              className="form-control"
              placeholderText="Check-out date"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="dropdown">
            <button
              className="btn btn-light border w-100 d-flex justify-content-between align-items-center"
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              {`${adults} adult${adults > 1 ? 's' : ''}, ${children} child${children > 1 ? 'ren' : ''}, ${rooms} room${rooms > 1 ? 's' : ''}`}
              <i className="fas fa-chevron-down"></i>
            </button>
            {showDropdown && (
              <div className="dropdown-menu p-3 w-100 show">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span>Adults</span>
                  <div className="d-flex align-items-center">
                    <button className="btn btn-secondary btn-sm" onClick={() => handleDecrement(setAdults, adults)}>-</button>
                    <span className="mx-2">{adults}</span>
                    <button className="btn btn-secondary btn-sm" onClick={() => handleIncrement(setAdults, adults)}>+</button>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span>Children</span>
                  <div className="d-flex align-items-center">
                    <button className="btn btn-secondary btn-sm" onClick={() => handleDecrement(setChildren, children)}>-</button>
                    <span className="mx-2">{children}</span>
                    <button className="btn btn-secondary btn-sm" onClick={() => handleIncrement(setChildren, children)}>+</button>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span>Rooms</span>
                  <div className="d-flex align-items-center">
                    <button className="btn btn-secondary btn-sm" onClick={() => handleDecrement(setRooms, rooms)}>-</button>
                    <span className="mx-2">{rooms}</span>
                    <button className="btn btn-secondary btn-sm" onClick={() => handleIncrement(setRooms, rooms)}>+</button>
                  </div>
                </div>
                <button className="btn btn-primary w-100" onClick={() => setShowDropdown(false)}>
                  Done
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Centering the Search Button */}
      <div className="d-flex justify-content-center mt-3">
        <button className="btn btn-primary fw-bold" style={{ width: '200px' }} onClick={handleSearch}>
          Search
        </button>
      </div>
    </div>
  );
};

export default Book;
