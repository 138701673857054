import React, { useState } from 'react';
import CommonHeading from "../common/CommonHeading";
import { Carousel } from 'react-bootstrap'; // Import Bootstrap's Carousel component

const OffersPage = () => {
  const [expandedImage, setExpandedImage] = useState(null);

  const toggleImageExpand = (imageId) => {
    if (expandedImage === imageId) {
      setExpandedImage(null);
    } else {
      setExpandedImage(imageId);
    }
  };

  const getWhatsAppLink = (altText) => {
    const phoneNumber = "9382167169";
    const message = `May I inquire about the ${altText} package? 😊`;
    return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  };

  const images = [
    { id: 1, src: '../assets/img/offers1.jpg', alt: 'Darjeeling 2 Night | 3 days' },
    { id: 2, src: '../assets/img/offers2.jpg', alt: 'Darjeeling & Dooars 3 Night | 4 days' },
    { id: 3, src: '../assets/img/offers3.jpg', alt: 'Darjeeling' },
  ];

  const additionalImages = {
    1: [
      { id: 4, src: '../assets/img/offers1.jpg', alt: 'Offers 1' },
      { id: 5, src: '../assets/img/offer1.1.jpg', alt: 'Offers 1.1' },
      { id: 6, src: '../assets/img/offer1.2.jpg', alt: 'Offers 1.2' },
      { id: 7, src: '../assets/img/offer1.3.jpg', alt: 'Offers 1.3' },
    ],
    2: [
      { id: 8, src: '../assets/img/offers2.jpg', alt: 'Offers 2' },
      { id: 9, src: '../assets/img/offer2.1.jpg', alt: 'Offers 2.1' },
      { id: 10, src: '../assets/img/offer2.2.jpg', alt: 'Offers 2.2' },
      { id: 11, src: '../assets/img/offer2.3.jpg', alt: 'Offers 2.3' },
      { id: 12, src: '../assets/img/offer2.4.jpg', alt: 'Offers 2.4' },
    ],
    3: [
      { id: 14, src: '../assets/img/offers3.jpg', alt: 'Offers 3' },
    ],
  };

  return (
    <div className="container mt-4">
      <CommonHeading heading="Our Offers" />
      <div className="row text-center">
        {images.map((image) => (
          <div key={image.id} className="col-12 col-md-4 mb-4 d-flex justify-content-center">
            <div className="position-relative mx-auto" style={{ width: '100%' }}>
              <img
                src={image.src}
                alt={image.alt}
                className="img-fluid border border-primary"
                style={{ borderRadius: '8px', cursor: 'pointer', width: '100%' }}
                onClick={() => toggleImageExpand(image.id)}
              />
              {expandedImage === image.id && (
                <div
                  className="position-fixed top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    zIndex: 1050,
                  }}
                >
                  <Carousel className="w-75 h-75">
                    {additionalImages[image.id].map((addImage) => (
                      <Carousel.Item key={addImage.id}>
                        <img
                          src={addImage.src}
                          alt={addImage.alt}
                          className="img-fluid"
                          style={{
                            borderRadius: '8px',
                            maxHeight: '50vh',
                            maxWidth: '100%',
                          }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <button
                    type="button"
                    className="btn-close position-absolute top-0 end-0 m-3"
                    onClick={() => toggleImageExpand(image.id)}
                    style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '50%' }}
                  />
                </div>
              )}
              <a
                href={getWhatsAppLink(image.alt)}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary fw-bold"
                style={{
                  position: 'absolute',
                  bottom: '-20px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  borderRadius: '10px',
                  backgroundColor: '#007bff',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#FEA116'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
              >
                Book
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OffersPage;
